export const filterArr = [
	[
		{
			label: '选择部门',
			type: 'select',
			attr: 'deptId',
			placeholder: '请选择',
			column: 8,
			labelWidth: '96px',
		},
		{
			label: '跟进人',
			type: 'select',
			attr: 'userId',
			placeholder: '请选择',
			column: 8,
			labelWidth: '96px',
		},
		{
			label: '客户',
			type: 'input',
			attr: 'search',
			placeholder: '搜索客户姓名或手机号',
			column: 8,
			labelWidth: '96px',
		},
	],
	[
		{
			label: '客户来源',
			type: 'select',
			attr: 'source',
			placeholder: '请选择',
			column: 8,
			labelWidth: '96px',
		},
		// {
		// 	label: '未跟进',
		// 	type: 'select',
		// 	attr: 'name',
		// 	placeholder: '请选择',
		// 	column: 8,
		// 	labelWidth: '96px',
		// },
		{
			label: '备注',
			type: 'input',
			attr: 'remark',
			placeholder: '请填写备注',
			column: 8,
			labelWidth: '96px',
		},
		{
			label: '跟进记录',
			type: 'input',
			attr: 'content',
			placeholder: '请填写要搜索的跟进记录',
			column: 8,
			labelWidth: '96px',
		},
	],
	[{ type: 'button', column: 24, isBtnText: true }],
]

export const allfilterArr = [
	[
		{
			label: '选择部门',
			type: 'select',
			attr: 'deptId',
			placeholder: '请选择',
			column: 8,
			labelWidth: '96px',
		},
		{
			label: '跟进人',
			type: 'select',
			attr: 'userId',
			placeholder: '请选择',
			column: 8,
			labelWidth: '96px',
		},
		{
			label: '客户',
			type: 'input',
			attr: 'search',
			placeholder: '搜索客户姓名或手机号',
			column: 8,
			labelWidth: '96px',
		},
	],
	[
		// {
		// 	label: '申请时间',
		// 	type: 'dateRange',
		// 	attr: 'name',
		// 	placeholder: '请选择',
		// 	column: 8,
		// 	labelWidth: '96px',
		// },
		{
			label: '客户星级',
			type: 'select',
			attr: 'starStatus',
			placeholder: '请选择',
			column: 8,
			labelWidth: '96px',
		},
		{
			label: '跟进时间',
			type: 'dateRange',
			valueFormat:"YYYY-MM-DD",
			attr: 'followDate',
			placeholder: '请选择',
			column: 8,
			labelWidth: '96px',
		},
		{
			label: '跟进状态',
			type: 'select',
			attr: 'status',
			placeholder: '请选择',
			column: 8,
			labelWidth: '96px',
			option: [],
		},
	],
	[
		{
			label: '客户属性',
			type: 'checkBoxButton',
			attr: 'customerAttr',
			placeholder: '请选择',
			column: 24,
			labelWidth: '96px',
			option: [
				{ label: '房产', val: 1 },
				{ label: '公积金', val: 2 },
				{ label: '社会保险', val: 3 },
				{ label: '车辆', val: 4 },
				{ label: '企业纳税', val: 5 },
				{ label: '保单', val: 6 },
				{ label: '营业执照', val: 7 },
				{ label: '代发', val: 8 },
			],
		},
	],
	[
		{
			label: '客户来源',
			type: 'select',
			attr: 'source',
			placeholder: '请选择',
			column: 8,
			labelWidth: '96px',
			option: [
				{ label: '导入', val: 0 },
				{ label: '系统提供', val: 1 },
			],
		},
		// {
		// 	label: '未跟进',
		// 	type: 'select',
		// 	attr: 'name',
		// 	placeholder: '请选择',
		// 	column: 8,
		// 	labelWidth: '96px',
		// },
		{
			label: '备注',
			type: 'input',
			attr: 'remark',
			placeholder: '请填写备注',
			column: 8,
			labelWidth: '96px',
		},
		{
			label: '跟进记录',
			type: 'input',
			attr: 'content',
			placeholder: '请填写要搜索的跟进记录',
			column: 8,
			labelWidth: '96px',
		},
	],

	[{ type: 'button', column: 24, isBtnText: true }],
]
export const tableColumn = [
	{
		label: '用户名',
		prop: 'name',
		width: '120px',
		fixed: true,
		isCustomize: true,
		tableType: 'nameNoPointer',
	},
	// {
	// 	label: 'ID',
	// 	prop: 'id',
	// 	width: '80px',
	// },
	{
		label: '年龄',
		prop: 'age',
		width: '80px',
	},
	{
		label: '性别',
		prop: 'sex',
		width: '80px',
		isCustomize: true,
		tableType: 'sexNumber',
	},
	{
		label: '额度',
		prop: 'loanLimit',
		width: '80px',
	},
	{
		label: '跟进人',
		prop: 'username',
		width: '120px',
	},
	{
		label: '星级',
		prop: 'starStatus',
		width: '80px',
		isCustomize: true,
		tableType: 'starStatus',
	},
	{
		label: '跟进状态',
		prop: 'status',
		width: '100px',
		isCustomize: true,
		tableType: 'followUpStatus',
	},
	{
		label: '客户来源',
		prop: 'channelName',
		width: '100px'
	},
	{
		label: '跟进情况备注(点击查看更多)',
		prop: 'followRecords',
		width: '250px',
		isCustomize: true,
		tableType: 'followRecords',
	},
	{
		label: '客户条件(备注)',
		prop: 'remark',
		width: '250px',
	},
	{
		label: '客户属性',
		prop: 'clientProperty',
		width: '250px',
	},
	{
		label: '申请时间',
		prop: 'createTime',
		width: '200px',
	},
]
